import React from 'react'
import InnerBanner from '../components/InnerBanner'
import MusicMood from '../components/MusicMood'
import TopHit from '../components/TopHit'
import Recording from '../components/Recording'

const Music = () => {
  return (
    <>
      <InnerBanner title='Music' />
      {/* <MusicMood /> */}
      {/* <TopHit /> */}
      <Recording />
      {/* <div className="load-more">
        <button className="theme-btn btn-outline">Explore More</button>
      </div> */}
    </>
  )
}

export default Music
