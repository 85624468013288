import React, { useEffect, useState } from 'react';
import { Modal, Table, Empty, Spin, message } from 'antd';
import { IoMdAdd } from "react-icons/io";
import { FiMinus } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";

const ActionButton = ({ record, playlist, messageApi, fetchPlaylists }) => {
    const [alreadyExist, setAlreadyExist] = useState(playlist.musicIds?.includes(record.key))
    const addMusic = async (key) => {
        messageApi.open({
            type: 'loading',
            key,
            content: 'Adding to Playlist..',
        })
        const res = await fetch('/api/playlist/addmusic', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ playlist: playlist._id, music: key })
        })
        const resJson = await res.json()
        if (resJson.success) {
            messageApi.open({
                type: 'success',
                key,
                content: 'Music Added to Playlist',
            })
            setAlreadyExist(true)
        } else {
            messageApi.open({
                type: 'error',
                key,
                content: resJson.error,
            })
        }
        fetchPlaylists()
    }
    const removeMusic = async (key) => {
        messageApi.open({
            type: 'loading',
            key,
            content: 'Removing from Playlist..',
        })
        const res = await fetch('/api/playlist/removemusic', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ playlist: playlist._id, music: key })
        })
        const resJson = await res.json()
        if (resJson.success) {
            messageApi.open({
                type: 'success',
                key,
                content: 'Music Removed from Playlist',
            })
            setAlreadyExist(false)
        } else {
            messageApi.open({
                type: 'error',
                key,
                content: resJson.error,
            })
        }
        fetchPlaylists()
    }
    return (
        <>
            {alreadyExist ? <button
                className='action-btn delete-btn'
                onClick={() => {
                    console.log('record', record)
                    removeMusic(record.key)
                }}
                title='Remove Music from Playlist'
            >
                <FiMinus />
            </button> : <button
                className='action-btn edit-btn'
                onClick={() => {
                    console.log('record', record)
                    addMusic(record.key)
                }}
                title='Add Music to Playlist'
            >
                <IoMdAdd />
            </button>}
        </>
    )
}

const AddMusicToPlaylist = ({ open, setOpen, playlist, fetchPlaylists }) => {
    const [searchResult, setSearchResult] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10
    });
    const handleSearch = async (e) => {
        e.preventDefault()
        setSearchResult(true)
        setLoading(true)
        const res = await fetch('/api/music/search', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ searchQuery })
        })
        const resJson = await res.json()
        console.log('resJson', resJson)
        if (resJson.success) {
            
            setMusics(resJson.musics.map((music) => ({
                key: music._id,
                thumbnail: <img className='table-thumbnail' src={'' + music.thumbnail} />,
                title: music.title,
                file: <audio controls><source src={'' + music.file} /></audio>,
            })))
        }
        setLoading(false)
    }
    const [musics, setMusics] = useState([])
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'File',
            dataIndex: 'file',
            key: 'file',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => <ActionButton playlist={playlist} record={record} messageApi={messageApi} fetchPlaylists={fetchPlaylists} />
        }
    ]
    const fetchMusics = async () => {
        setSearchQuery('')
        setLoading(true)
        setSearchResult(false)
        const res = await fetch('/api/music/fetch', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const resJson = await res.json()
        console.log('resJson', resJson)
        if (resJson.success) {
            
            setMusics(resJson.musics.map((music) => ({
                key: music._id,
                thumbnail: <img className='table-thumbnail' src={'' + music.thumbnail} />,
                title: music.title,
                file: <audio controls><source src={'' + music.file} /></audio>,
            })))
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchMusics()
    }, [])
    return (
        <>
            {contextHolder}
            <Modal
                title="Add Music To Playlist"
                centered
                open={open}
                onOk={() => {
                    setOpen(false)
                }}
                onCancel={() => {
                    setOpen(false)
                }}
                width={1000}
                className='music-modal'
            >
                {loading ? <div className='data-loader-wrapper'><Spin className='data-loader' size="large" /></div>
                    : <>
                            <form onSubmit={handleSearch}>
                                <div className="search-field">
                                    <input value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value) }} type="text" placeholder='Search Here...' required />
                                    <button type='submit'><IoSearch /></button>
                                </div>
                            </form>
                            {searchResult && <button className='clear-search' onClick={fetchMusics}>Clear Search</button>}
                        {musics.length > 0 ? <>
                            <Table pagination={pagination} bodySortBg='#111' columns={columns} dataSource={musics} />
                        </> : <Empty className='empty-data' />}
                    </>}
            </Modal>
        </>
    );
};
export default AddMusicToPlaylist;