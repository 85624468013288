import React, { useState, useEffect, useContext } from 'react'
import { Drawer } from 'antd'
import { Link } from 'react-router-dom'
import { MusicContext } from '../context/Music'

const SearchDrawer = ({ drawerOpen, setDrawerOpen }) => {
    const { setPlayingMusic } = useContext(MusicContext)
    const [searchQuery, setSearchQuery] = useState('')
    const [musics, setMusics] = useState('')
    const onClose = () => {
        setDrawerOpen(false);
    };
    const handleChange = async (e) => {
        setSearchQuery(e.target.value)
    }
    const handleSearch = async () => {
        if (searchQuery) {
            const response = await fetch('/api/music/search', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ searchQuery })
            })
            const result = await response.json()
            console.log('result', result)
            setMusics(result.musics)
        }
    }
    useEffect(() => {
        handleSearch()
    }, [searchQuery])

    return (
        <Drawer className='search-drawer' title="Search..." onClose={onClose} placement='top' open={drawerOpen}>
            <div className="container">
                <div className="search-wrap">
                    <div className="input-field search-field">
                        <input onChange={handleChange} placeholder='Search Here...' type="text" name="" />
                        <button onClick={handleSearch} className="theme-btn btn-white search-btn">Search</button>
                    </div>
                    {musics.length > 0 && <ul className="search-list">
                        {musics.map((item) => {
                            return <li><button onClick={()=>{
                                setPlayingMusic({
                                    title: item.title,
                                    category: 'Hip-Hop',
                                    uploaded: '4 months ago',
                                    artist: item.artist?.firstName + ' ' + item.artist?.lastName,
                                    artistImg: '' + item.thumbnail,
                                    barWidth: 3,
                                    barGap: 3,
                                    musicFile:'' + item.file,
                                    // comments: music.comments
                                    // id: music._id,
                                    // thumbnail: <img className='table-thumbnail' src={'' + music.thumbnail} />,
                                    // title: music.title,
                                    // artist: music.artist?.firstName + ' ' + music.artist?.lastName,
                                    // album: music.album?.title,
                                    // releaseDate: music.releaseDate,
                                    // tags: music.tags,
                                    // file: <audio controls><source src={'' + music.file} /></audio>,
                                  })
                                onClose()
                            }}>{item.title}</button></li>
                        })}
                    </ul>}
                </div>
            </div>
        </Drawer>
    )
}

export default SearchDrawer
