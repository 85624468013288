import React, { useEffect, useState } from 'react';
import { FloatButton, Row, Col, Tag, Empty, Spin } from 'antd';
import Sidebar from '../components/admin/Sidebar';
import AdminTable from './AdminTable';
import { FiPlus } from "react-icons/fi";
import ArtistFormDrawer from '../components/admin/ArtistFormDrawer';
import ArtistUpdateFormDrawer from '../components/admin/ArtistUpdateFormDrawer';
import { IoSearch } from "react-icons/io5";

const AdminArtist = () => {
    const [loading, setLoading] = useState(true)
    const [searchResult, setSearchResult] = useState(true)
    const [searchQuery, setSearchQuery] = useState('')
    const [data, setData] = useState([])
    const [updateObj, setUpdateObj] = useState(null)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openUpdateDrawer, setOpenUpdateDrawer] = useState(false)
    useEffect(() => {
        fetchArtists()
    }, [])

    const handleSearch = async (e) => {
        e.preventDefault()
        setSearchResult(true)
        setLoading(true)
        const res = await fetch('/api/artist/search', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ searchQuery })
        })
        const resJson = await res.json()
        console.log('resJson', resJson)
        if (resJson.success) {
            setData(resJson.artists.map((artist) => ({
                key: artist._id,
                thumbnail: <img className='table-thumbnail' src={'' + artist.avatar} />,
                firstName: artist.firstName,
                lastName: artist.lastName,
                tags: artist.tags,
                actions: {
                    delete: '/api/artist/delete',
                    data: artist
                }
            })))
        }
        setLoading(false)
    }
    const fetchArtists = async () => {
        setLoading(true)
        setSearchResult(false)
        setSearchQuery('')
        const res = await fetch('/api/artist/fetch', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const resJson = await res.json()
        console.log('resJson', resJson)
        if (resJson.success) {
            setData(resJson.artists.map((artist) => ({
                key: artist._id,
                thumbnail: <img className='table-thumbnail' src={'' + artist.avatar} />,
                firstName: artist.firstName,
                lastName: artist.lastName,
                tags: artist.tags,
                actions: {
                    delete: '/api/artist/delete',
                    data: artist
                }
            })))
        }
        setLoading(false)
    }
    const columns = [
        {
            title: 'Thumbnail',
            dataIndex: 'thumbnail',
            key: 'thumbnail',
            // width: '15%',
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            // width: '30%',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            // width: '30%',
        },
        {
            title: 'Tags',
            key: 'tags',
            dataIndex: 'tags',
            render: (_, { tags }) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? 'geekblue' : 'green';
                        if (tag === 'loser') {
                            color = 'volcano';
                        }
                        return (
                            <Tag className='table-tag' color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
    ];
    return (
        <>
            <div class="dashboard-wrapper">
                <Row gutter={{ xl: 30, lg: 20, xs: 15 }}>
                    <Col xl={3}>
                        <Sidebar />
                    </Col>
                    <Col xl={21}>
                        <div className="dashboard-content">
                            <div className="card-head">
                                <h3 className="theme-h3">Artists</h3>
                                <div>
                                    <form onSubmit={handleSearch}>
                                        <div className="search-field">
                                            <input value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value) }} type="text" placeholder='Search Here...' required />
                                            <button type='submit'><IoSearch /></button>
                                        </div>
                                    </form>
                                    {searchResult && <button className='clear-search' onClick={fetchArtists}>Clear Search</button>}
                                </div>
                            </div>
                            {
                                loading ? <div className='data-loader-wrapper'><Spin className='data-loader' size="large" /></div> : <>
                                    {data.length > 0 ? <AdminTable fetchData={fetchArtists} columns={columns} data={data} setOpenUpdateDrawer={setOpenUpdateDrawer} setUpdateObj={setUpdateObj} /> : <Empty className='empty-data' />}
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            <FloatButton onClick={() => { setOpenDrawer(true) }} className='admin-float-btn' type="primary" icon={<FiPlus />} tooltip={<span className='float-tooltip'>Add New Artist</span>} />
            <ArtistFormDrawer open={openDrawer} setOpen={setOpenDrawer} fetchData={fetchArtists} />
            {updateObj && <ArtistUpdateFormDrawer open={openUpdateDrawer} setOpen={setOpenUpdateDrawer} fetchData={fetchArtists} obj={updateObj} setObj={setUpdateObj} />}
        </>
    )
};
export default AdminArtist;