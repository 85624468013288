import React from 'react'
import InnerBanner from '../components/InnerBanner'
import TopHit from '../components/TopHit'
import MadeForYou from '../components/MadeForYou'

const Playlist = () => {
    return (
        <>
            <InnerBanner title='Playlists' />
            <TopHit />
            {/* <MadeForYou /> */}
            {/* <div className="load-more">
                <button className="theme-btn btn-outline">Explore More</button>
            </div> */}
        </>
    )
}

export default Playlist
