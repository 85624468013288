import React from 'react'
import InnerBanner from '../components/InnerBanner'
import Recording from '../components/Recording'

const NewRelease = () => {
  return (
    <>
      <InnerBanner title='New Release' />
      <Recording />
      <div className="load-more">
        <button className="theme-btn btn-outline">Explore More</button>
      </div>
    </>
  )
}

export default NewRelease
