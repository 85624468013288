import React from 'react'
import changeGame from '../images/change-the-game.png'
import { Link } from 'react-router-dom'

const ChangingTheGame = () => {
  return (
    <>
      <section className="change-game-sec">
        <div className="container">
            <h2 className="sec-title">Changing The Game</h2>
            <img className='featured-img' src={changeGame} alt="" />
            <Link className='join-us'>Join <br />Us</Link>
        </div>
      </section>
    </>
  )
}

export default ChangingTheGame
