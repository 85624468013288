import React from 'react'

const InnerBanner = ({ title }) => {
    return (
        <section className='inner-banner'>
            <div className="container">
                <h2 className="theme-h1">{title}</h2>
            </div>
        </section>
    )
}

export default InnerBanner
