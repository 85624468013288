import { Col, Row } from 'antd'
import React from 'react'

const ContactForm = () => {
    return (
        <section className='contact-form'>
            <div className="container">
                <Row style={{ justifyContent: 'center' }}>
                    <Col xl={16}>
                        <div className="form-elem">
                            <h3 className="sec-title">Contact to Clarke’s Music Services</h3>
                            {/* <img className='img-fluid' src={contactImg} alt="" /> */}
                            <Row gutter={[{ xl: 20 }, { xl: 20 }]}>
                                <Col xl={12} xs={24}>
                                    <div className="input-field">
                                        <label htmlFor="">First Name</label>
                                        <input placeholder='First Name' type="text" required />
                                    </div>
                                </Col>
                                <Col xl={12} xs={24}>
                                    <div className="input-field">
                                        <label htmlFor="">Last Name</label>
                                        <input placeholder='Last Name' type="text" required />
                                    </div>
                                </Col>
                                <Col xs={24}>
                                    <div className="input-field">
                                        <label htmlFor="">Email</label>
                                        <input placeholder='Email' type="email" required />
                                    </div>
                                </Col>
                                <Col xs={24}>
                                    <div className="input-field">
                                        <label htmlFor="">Phone</label>
                                        <input placeholder='Phone' type="tel" required />
                                    </div>
                                </Col>
                                <Col xs={24}>
                                    <div className="input-field">
                                        <label htmlFor="">Message</label>
                                        <textarea placeholder='Message' name=""></textarea>
                                    </div>
                                </Col>
                            </Row>
                            <button className="theme-btn btn-white">Submit</button>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default ContactForm
