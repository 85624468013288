import React, { useEffect, useState } from 'react';
import { FloatButton, Row, Col, Tag, Empty, Spin } from 'antd';
import Sidebar from '../components/admin/Sidebar';
import AdminTable from './AdminTable';
import { FiPlus } from "react-icons/fi";
import AlbumUpdateFormDrawer from '../components/admin/AlbumUpdateFormDrawer';
import { IoSearch } from "react-icons/io5";
import AlbumFormDrawer from '../components/admin/AlbumFormDrawer';

const AdminAlbums = () => {
    const [loading, setLoading] = useState(true)
    const [searchResult, setSearchResult] = useState(true)
    const [searchQuery, setSearchQuery] = useState('')
    const [data, setData] = useState([])
    const [updateObj, setUpdateObj] = useState(null)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openUpdateDrawer, setOpenUpdateDrawer] = useState(false)
    useEffect(() => {
        fetchAlbums()
    }, [])

    const handleSearch = async (e) => {
        e.preventDefault()
        setSearchResult(true)
        setLoading(true)
        const res = await fetch('/api/album/search', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ searchQuery })
        })
        const resJson = await res.json()
        console.log('resJson', resJson)
        if (resJson.success) {
            setData(resJson.albums.map((album) => ({
                key: album._id,
                thumbnail: <img className='table-thumbnail' src={'' + album.thumbnail} />,
                title: album.title,
                artist: album.artist?.firstName + ' ' + album.artist?.lastName,
                description: album.description,
                tags: album.tags,
                actions: {
                    delete: '/api/album/delete',
                    data: album
                }
            })))
        }
        setLoading(false)
    }
    const fetchAlbums = async () => {
        setLoading(true)
        setSearchResult(false)
        setSearchQuery('')
        const res = await fetch('/api/album/fetch', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const resJson = await res.json()
        console.log('resJson', resJson)
        if (resJson.success) {
            setData(resJson.albums.map((album) => ({
                key: album._id,
                thumbnail: <img className='table-thumbnail' src={'' + album.thumbnail} />,
                title: album.title,
                artist: album.artist.firstName + ' ' + album.artist.lastName,
                description: album.description,
                tags: album.tags,
                actions: {
                    delete: '/api/album/delete',
                    data: album
                }
            })))
        }
        setLoading(false)
    }
    const columns = [
        {
            title: 'Thumbnail',
            dataIndex: 'thumbnail',
            key: 'thumbnail',
            // width: '15%',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            // width: '15%',
        },
        {
            title: 'Artist',
            dataIndex: 'artist',
            key: 'artist',
            // width: '30%',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            // width: '30%',
        }
    ];
    return (
        <>
            <div class="dashboard-wrapper">
                <Row gutter={{ xl: 30, lg: 20, xs: 15 }}>
                    <Col xl={3}>
                        <Sidebar />
                    </Col>
                    <Col xl={21}>
                        <div className="dashboard-content">
                            <div className="card-head">
                                <h3 className="theme-h3">Albums</h3>
                                <div>
                                    <form onSubmit={handleSearch}>
                                        <div className="search-field">
                                            <input value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value) }} type="text" placeholder='Search Here...' required />
                                            <button type='submit'><IoSearch /></button>
                                        </div>
                                    </form>
                                    {searchResult && <button className='clear-search' onClick={fetchAlbums}>Clear Search</button>}
                                </div>
                            </div>
                            {
                                loading ? <div className='data-loader-wrapper'><Spin className='data-loader' size="large" /></div> : <>
                                    {data.length > 0 ? <AdminTable fetchData={fetchAlbums} columns={columns} data={data} setOpenUpdateDrawer={setOpenUpdateDrawer} setUpdateObj={setUpdateObj} /> : <Empty className='empty-data' />}
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            <FloatButton onClick={() => { setOpenDrawer(true) }} className='admin-float-btn' type="primary" icon={<FiPlus />} tooltip={<span className='float-tooltip'>Add New Album</span>} />
            <AlbumFormDrawer open={openDrawer} setOpen={setOpenDrawer} fetchData={fetchAlbums} />
            {updateObj && <AlbumUpdateFormDrawer open={openUpdateDrawer} setOpen={setOpenUpdateDrawer} fetchData={fetchAlbums} obj={updateObj} setObj={setUpdateObj} />}
        </>
    )
}

export default AdminAlbums
