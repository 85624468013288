import { createContext, useState } from "react";

export const MusicContext = createContext(null)

export const MusicProvider = (props) => {
    const [playingMusic, setPlayingMusic] = useState(null)

    return (
        <MusicContext.Provider value={{ playingMusic, setPlayingMusic }} >
            {props.children}
        </MusicContext.Provider>
    )
}