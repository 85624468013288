import React, { useEffect, useState } from 'react';
import {
    DashboardOutlined,
    DesktopOutlined,
} from '@ant-design/icons';
import { RiUserVoiceFill } from "react-icons/ri";
import { PiPlaylistDuotone } from "react-icons/pi";
import { IoMusicalNotes } from "react-icons/io5";
import { BiAlbum } from "react-icons/bi";
import { MdLogout } from "react-icons/md";
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const location = useLocation()
    const [active, setActive] = useState(null)
    useEffect(() => {
        setActive(location.pathname)
    }, [location.pathname])
    useEffect(() => {
        console.log('active', active)
    }, [active])
    const items = [
        {
            key: '/',
            icon: <DesktopOutlined />,
            label: <Link className="admin-sidebar-link" to='/'>Website</Link>,
        },
        {
            key: '/admin',
            icon: <DashboardOutlined />,
            label: <Link className="admin-sidebar-link" to='/admin'>Dashboard</Link>,
        },
        {
            key: '/admin/artist',
            icon: <RiUserVoiceFill />,
            label: <Link className="admin-sidebar-link" to='/admin/artist'>Artist</Link>,
        },
        {
            key: '/admin/album',
            icon: <BiAlbum />,
            label: <Link className="admin-sidebar-link" to='/admin/album'>Albums</Link>,
        },
        {
            key: '/admin/music',
            icon: <IoMusicalNotes />,
            label: <Link className="admin-sidebar-link" to='/admin/music'>Music</Link>,
        },
        {
            key: '/admin/playlist',
            icon: <PiPlaylistDuotone />,
            label: <Link className="admin-sidebar-link" to='/admin/playlist'>Playlists</Link>,
        },
        {
            key: '/admin/logout',
            icon: <MdLogout />,
            label: <Link className="admin-sidebar-link" to='/admin/logout'>Log Out</Link>,
        },
        // {
        //     key: '/artist',
        //     label: 'Artist',
        //     icon: <RiUserVoiceFill />,
        //     children: [
        //         {
        //             key: '5',
        //             label: <Link className="admin-sidebar-link" to='/'>View List</Link>,
        //         },
        //         {
        //             key: '6',
        //             label: <Link className="admin-sidebar-link" to='/'>Add New</Link>,
        //         },
        //         {
        //             key: '7',
        //             label: <Link className="admin-sidebar-link" to='/'>View List</Link>,
        //         },
        //         {
        //             key: '8',
        //             label: 'Option 8',
        //         },
        //     ],
        // }
    ];
    return (
        <>
            <Menu
                className='admin-sidebar'
                defaultSelectedKeys={[active]}
                mode="inline"
                theme="dark"
                items={items}
            />
        </>
    )
}
export default Sidebar;