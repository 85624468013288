import React from 'react'
import InnerBanner from '../components/InnerBanner'
import MusicMood from '../components/MusicMood'
import TopHit from '../components/TopHit'

const Music = () => {
  return (
    <>
      <InnerBanner title='TOP CHARTS' />
      <MusicMood />
      <TopHit />
    </>
  )
}

export default Music
