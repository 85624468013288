import React, { useEffect, useState, useContext } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import Logo from '../images/logo.png'
import { DownloadOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons'
import { UserDataContext } from '../context/User';
import SearchDrawer from './SearchDrawer';

const Header = () => {
    const { userData, logOut } = useContext(UserDataContext)
    const [drawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation()
    const [currentPage, setCurrentPage] = useState('')
    useEffect(() => {
        setCurrentPage(location.pathname)
        console.log("currentPage", currentPage)
    }, [location])
    const pages = [
        {
            title: 'Home',
            path: '/'
        },
        {
            title: 'Music',
            path: '/music'
        },

        {
            title: 'New Release',
            path: '/new-release'
        },
        {
            title: 'Featured Artists',
            path: '/featured-artists'
        },
        {
            title: 'Playlists',
            path: '/playlists'
        },
        {
            title: 'About Us',
            path: '/about-us'
        },
        {
            title: 'Contact Us',
            path: '/contact-us'
        }

    ]
    return (
        <>
            <header>
                <div className="container">


                    <nav>
                        <Link to='/'>
                            <img src={Logo} className='img-fluid' alt="" />
                        </Link>
                        <ul className='nav-links'>
                            {pages.map((page) => {
                                return (
                                    <li>
                                        <NavLink
                                            exact activeClassName="active"
                                            to={page.path}
                                            className={currentPage === page.path ? 'active-link' : ''}
                                        >{page.title}</NavLink>
                                    </li>
                                )
                            })}
                        </ul>
                        <ul className="social-links">
                            <li>
                                <button className='nav-icon-btn' onClick={() => { setDrawerOpen(true) }}>
                                    <SearchOutlined />
                                </button>
                            </li>
                            {/* <li>
                                <Link className="nav-icon-btn" to="/">
                                    <DownloadOutlined />
                                </Link>
                            </li> */}
                            {userData?.email ? <li className='user-exist'>
                                <span className="nav-icon-btn">
                                    <UserOutlined />
                                </span>
                                <ul className="hvr-item">
                                    {userData?.role === 'admin' && <>
                                        <li>
                                            <Link className='drp-item' to='/admin'>Visit Admin Panel</Link>
                                        </li>
                                        <li>
                                            <button className='drp-item' onClick={logOut}>Log Out</button>
                                        </li>
                                    </>}
                                </ul>
                            </li> : <li>
                                <Link className="nav-icon-btn" to="/login">
                                    <UserOutlined />
                                </Link>
                            </li>}
                        </ul>
                    </nav>
                </div>
            </header>
            <SearchDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        </>
    )
}

export default Header
