import React from 'react'
import Homebanner from '../components/Homebanner'
import About from '../components/About'
import Services from '../components/Services'
import Albums from '../components/Albums'
import Community from '../components/Music_community'
import Listening from '../components/Listing_option'
import Artist from '../components/Artist'
import Recording from '../components/Recording'
import MusicMood from '../components/MusicMood'
import TopHit from '../components/TopHit'
import MadeForYou from '../components/MadeForYou'
import ChangingTheGame from '../components/ChangingTheGame'
import SubscribedToUpdate from '../components/SubscribedToUpdate'

const Home = () => {
  return (
    <>
      <Homebanner />
      <About />
      <Services />
      <Albums />
      <Community />
      <Listening />   
      <Artist /> 
      <Recording />
      <MusicMood />
      <TopHit />
      {/* <MadeForYou /> */}
      <ChangingTheGame />
      <SubscribedToUpdate />
    </>
  )
}

export default Home
