import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompactDisc } from '@fortawesome/free-solid-svg-icons';
import { IoLogoFacebook } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa6";
import { RiTwitterXFill } from "react-icons/ri";
import { AiOutlineYoutube } from "react-icons/ai";
import { IoMusicalNotesOutline } from "react-icons/io5";
import { RiUserVoiceFill } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Homebanner = () => {

  return (
    <>
      <section className="home-banner">
        <div className="container">
          <div className="main-banner">
            <div className="main-links">
              <ul>
                <li>
                  <Link to="/"><RiUserVoiceFill /></Link>
                </li>
                <li>
                  <Link to="/"><FontAwesomeIcon icon={faCompactDisc} /></Link>
                </li>
                <li>
                  <Link to="/"><IoMusicalNotesOutline /></Link>
                </li>
              </ul>
            </div>
            <div className="banner-content">
              {/* <h1 className="theme-h1">CLARKE’S</h1> */}
              <h1 className="custom-h1">CLARKE’S</h1>
              <h1 className='custom-h1'>MUSIC</h1>
              <h1 className="custom-h1">SERVICES</h1>
              <h6>Immerse yourself in a world of melodies and rhythms as you explore our vast library of genres, from indie rock to electronic beats.</h6>
            </div>
            <div className="main-links">
              <ul>
                <li>
                  <a href="/"><IoLogoFacebook /></a>
                </li>
                <li>
                  <a href="/"><FaInstagram /></a>
                </li>
                <li>
                  <a href="/"><RiTwitterXFill /></a>
                </li>
                <li>
                  <a href="/"><AiOutlineYoutube /></a>
                </li>
              </ul>
            </div>
          </div>
          <div className="arrow-section" >
            <Link className="hvr-cntnt" data-content="Thank you for subscribing to Clarke’s Music Services!" href="/" ><FaArrowRight /></Link>
          </div>
          {/* {isSubscriptionVisible && ( */}
            {/* <div className="subscription-content">
              Thank you for subscribing to Clarke’s Music Services!
            </div> */}
          {/* )} */}
        </div>
      </section>
    </>
  );
}

export default Homebanner;
