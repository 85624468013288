import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompactDisc } from '@fortawesome/free-solid-svg-icons';
import Singer from '../images/singer-1.png'
import Singer_1 from '../images/singer-2.png'
import Singer_2 from '../images/singer-3.png'
import { SiYoutubemusic } from "react-icons/si";
import { FaRegDotCircle } from "react-icons/fa";
import { GoDot } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa";


import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';

function Albums() {
    const [albums, setAlbums] = useState([])
    const fetchAlbums = async () => {
        const res = await fetch('/api/album/fetch', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const resJson = await res.json()
        console.log('resJson', resJson)
        if (resJson.success) {

            setAlbums(resJson.albums)
        }
    }
    useEffect(() => {
        fetchAlbums()
    }, [])
    return (
        <div>
            <section className='Featured_Albums'>
                <div className="container">
                    <div className="feature_text">
                        <h2>
                            <span className="icon-after">
                                <FontAwesomeIcon icon={faCompactDisc} spinPulse />
                            </span>
                            Featured Albums
                            <span className="icon-before">
                                <FontAwesomeIcon icon={faCompactDisc} spinPulse spinPulseReverse />

                            </span>
                        </h2>
                    </div>
                    <div className="feature_black">
                        <div className="main_feature">
                            {albums.map((album, index) => {
                                return <>
                                    {index % 2 === 0 ? <Row className='' style={{ alignItems: 'center', margin: '0 0 30px 0' }}>
                                        <Col span={12}>
                                            <div className="ture_host">
                                                <h6><span><  FaRegDotCircle /></span>{album.artist?.firstName} {album.artist?.lastName}</h6>
                                                <h2>{album.title}</h2>
                                                <p>{album.description}</p>
                                                <Link to={"/album/" + album._id}>Listen Now <SiYoutubemusic /></Link>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="host_ture">
                                                <img src={'' + album.thumbnail} className='img-fluid' alt="img" />
                                                <div className="flex-btn">
                                                    <Link to="/"> < GoDot />Pop</Link>
                                                    <span></span>
                                                    <Link to="/"> < GoDot />R&B</Link>
                                                </div>
                                                <div className="main-looper">
                                                    <span><FaArrowRight /></span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row> : <Row gutter={40} className='' style={{ alignItems: 'center', margin: '0 0 30px 0' }}>
                                        <Col span={12}>
                                            <div className="host_ture">
                                                <img src={'' + album.thumbnail} className='img-fluid' alt="img" />
                                                <div className="flex-btn">
                                                    <Link to="/"> < GoDot />Pop</Link>
                                                    <span></span>
                                                    <Link to="/"> < GoDot />Synth</Link>
                                                </div>
                                                <div className="main-looper">
                                                    <Link to={"/album/" + album._id}><FaArrowRight /></Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="ture_host">
                                                <h6><span><FaRegDotCircle /></span>{album.artist?.firstName} {album.artist?.lastName}</h6>
                                                <h2>{album.title}</h2>
                                                <p>{album.description}</p>
                                                <Link to={"/album/" + album._id}>Listen Now <SiYoutubemusic /></Link>
                                            </div>
                                        </Col>
                                    </Row>}
                                </>
                            })}
                            {/* <Row className='' style={{ alignItems: 'center', margin: '0 0 30px 0' }}>
                                <Col span={12}>
                                    <div className="ture_host">
                                        <h6><span><  FaRegDotCircle /></span>Post Malone</h6>
                                        <h2>Twelve Carrot <span>Toothache</span></h2>
                                        <p>In 2020, Post Malone released an album titled "Twelve Carrot Toothache." This self-titled project further demonstrated his ability to experiment with various sounds while maintaining a cohesive body of work</p>
                                        <Link to="/">Listen Now <SiYoutubemusic /></Link>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="host_ture">
                                        <img src={Singer} className='img-fluid' alt="img" />
                                        <div className="flex-btn">
                                            <Link to="/"> < GoDot />Pop</Link>
                                            <span></span>
                                            <Link to="/"> < GoDot />R&B</Link>
                                        </div>
                                        <div className="main-looper">
                                            <span><FaArrowRight /></span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={40} className='' style={{ alignItems: 'center', margin: '0 0 30px 0' }}>
                                <Col span={12}>
                                    <div className="host_ture">
                                        <img src={Singer_1} className='img-fluid' alt="img" />
                                        <div className="flex-btn">
                                            <Link to="/"> < GoDot />Pop</Link>
                                            <span></span>
                                            <Link to="/"> < GoDot />Synth</Link>
                                        </div>
                                        <div className="main-looper">
                                            <Link><FaArrowRight /></Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="ture_host">
                                        <h6><span><FaRegDotCircle /></span>Billie Eilish</h6>
                                        <h2>Don’t Smile At <span>Me</span></h2>
                                        <p>Billie Eilish's debut extended play (EP) "dont smile at me" played a pivotal role in introducing her unique sound and establishing her as a force in the music industry. Here's a brief overview of the EP</p>
                                        <Link to="/">Listen Now <SiYoutubemusic /></Link>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='' style={{ alignItems: 'center', margin: '0 0 30px 0' }}>
                                <Col span={12}>
                                    <div className="ture_host">
                                        <h6><span><  FaRegDotCircle /></span>Juice Wrld</h6>
                                        <h2>Fighting <span>Demons</span></h2>
                                        <p>“Fighting Demons" was released posthumously in July 2020, following Juice WRLD's untimely death in December 2019. The album features collaborations with many artists.</p>
                                        <Link to="/">Listen Now <SiYoutubemusic /></Link>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="host_ture">
                                        <img src={Singer_2} className='img-fluid' alt="img" />
                                        <div className="flex-btn">
                                            <Link to="/"> < GoDot />Pop</Link>
                                            <span></span>
                                            <Link to="/"> < GoDot />R&B</Link>
                                            <span></span>
                                            <Link to="/"> < GoDot />Indie</Link>
                                            <span></span>
                                            <Link to="/"> < GoDot />Synth</Link>
                                        </div>
                                        <div className="main-looper">
                                            <span><FaArrowRight /></span>
                                        </div>
                                    </div>
                                </Col>
                            </Row> */}
                            {/* <a className='view-more-btn' href="/">Explore More</a> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Albums
