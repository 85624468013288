import { Col, Row } from 'antd'
import React from 'react'

const SubscribedToUpdate = () => {
    return (
        <>
            <section className="subscribed-update">
                <div className="container">
                    <Row style={{ justifyContent: 'center' }}>
                        <Col lg={18} xs={12}>
                            <div className="content-wrapper">
                                <h2 className="sec-title">Subscribed to Stay Updated</h2>
                                <Row gutter={50}>
                                    <Col span={8}>
                                        <div className="input-field">
                                            <label htmlFor="">Full Name</label>
                                            <input type="text" placeholder='John Doe' />
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="input-field">
                                            <label htmlFor="">Email Address</label>
                                            <input type="email" placeholder='johndoe32@gmail.com' />
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="input-field">
                                            <label htmlFor="">Phone Number</label>
                                            <input type="tel" placeholder='+123 456 7890' />
                                        </div>
                                    </Col>
                                </Row>
                                <button className="view-more-btn">
                                    Subscribed
                                </button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}

export default SubscribedToUpdate
