import React, { useState } from 'react'
import { IoClose } from "react-icons/io5";

const TagInput = ({tags, setTags}) => {
    const [val, setVal] = useState('')
    const handleTag = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            if(val !== ''){
                setTags([...tags, val]);
                setVal('');
            }
        }
    }
    const removeTag = (index) => {
        const newTags = [...tags];
        newTags.splice(index, 1);
        setTags(newTags);
    }
    return (
        <ul className='tags-wrapper'>
            {tags?.map((tag, ind) => <li key={ind} className='tag-item'>{tag} <button type='button' onClick={() => { removeTag(ind) }}><IoClose /></button></li>)}
            <li>
                {/* <form onSubmit={handleTag}> */}
                <input onKeyDown={handleTag} value={val} onChange={(e) => { setVal(e.target.value) }} placeholder='New Tag' type="text" onBlur={()=>{
                    if(val !== ''){
                        setTags([...tags, val]);
                        setVal('');
                    }
                }} />
                {/* </form> */}
            </li>
        </ul>
    )
}

export default TagInput
