import React, { useEffect, useState } from 'react';
import { Col, Drawer, Button, Row, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import UploadAvatar from './UploadAvatar';


const PlaylistFormDrawer = ({ open, setOpen, fetchData }) => {
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, text) => {
        api[type]({
            message: title,
            description: text,
        });
    };
    const [submitting, setSubmitting] = useState(false)
    const [formData, setFormData] = useState({
        thumbnail: '',
        title: '',
        album: '',
        releaseDate: '',
        artist: '',
        file: '',
        tags: []
    })
    const [imgUrl, setImgUrl] = useState('')
    const [tags, setTags] = useState([])
    const onClose = () => {
        setOpen(false);
    };
    const handleMusics = (value) => {
        console.log(`selected ${value}`);
      };
      
    useEffect(() => {
        setFormData({ ...formData, thumbnail: imgUrl, tags })
        console.log('tags', tags)
        console.log('imgUrl', imgUrl)
        console.log('formData', formData)
    }, [tags, imgUrl])
    const handleSubmit = async (e) => {
        try {
            setSubmitting(true)
            e.preventDefault()
            let error = false
            // thumbnail    title   album   releaseDate     artist      file    tags
            if (!formData.thumbnail) {
                error = true
                openNotificationWithIcon('error', 'Validation Error', 'Thumbnail is Required')
            }
            if (!formData.title) {
                error = true
                openNotificationWithIcon('error', 'Validation Error', 'Title is Required')
            }
            if (!error) {
                const res = await fetch('/api/playlist/new', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(formData)
                })
                const resJson = await res.json()
                if (resJson.success) {
                    openNotificationWithIcon('success', 'Success', `New Playlist has been Created!`)
                    setSubmitting(false)
                    setFormData({
                        thumbnail: '',
                        title: '',
                        album: '',
                        releaseDate: '',
                        artist: '',
                        file: '',
                        tags: []
                    })
                    setTags([])
                    setImgUrl('')
                    setSubmitting(false)
                    setOpen(false)
                    fetchData()
                } else {
                    setSubmitting(false)
                    openNotificationWithIcon('error', 'Error', resJson.error)
                }
            }
            setSubmitting(false)
        } catch (error) {
            openNotificationWithIcon('error', 'Validation Error', error.message)
            setSubmitting(false)
        }

    }
    return (
        <>
            {contextHolder}
            <Drawer
                className='admin-form-drawer'
                title="Add New Playlist"
                width={520}
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <div className="admin-form">
                    <form onSubmit={handleSubmit}>
                        <Row gutter={[{ xl: 20 }, { xl: 20 }]}>
                            <Col xl={24}>
                                <div className="input-field image-upload">
                                    <label>Thumbnail</label>
                                    <UploadAvatar imageUrl={imgUrl} setImageUrl={setImgUrl} />
                                </div>
                            </Col>
                            <Col xl={24}>
                                <div className="input-field">
                                    <label>Title</label>
                                    <input value={formData.title} onChange={(e) => { setFormData({ ...formData, title: e.target.value }) }} type="text" />
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div className="input-field">
                                    <Button disabled={submitting} type="primary" size='large' htmlType='submit'>
                                        {submitting ? <LoadingOutlined /> : <></>}
                                        Submit
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>
            </Drawer>
        </>

    )
}

export default PlaylistFormDrawer
