import React, { useEffect, useRef, useState, useContext } from 'react'
import { Col, Row } from 'antd';
import $ from 'jquery'
import WaveSurfer from 'wavesurfer.js'
import { FaPlay, FaPause, FaRegComment } from "react-icons/fa";
import { SlLike } from "react-icons/sl";
import { FiShare, FiDownload } from "react-icons/fi";
import { MusicContext } from '../context/Music';


const MusicItem = ({ musicItem }) => {
  const { setPlayingMusic } = useContext(MusicContext)
  const musicRef = useRef(null)
  const commentRef = useRef(null)
  const [musicPlayed, setMusicPlayed] = useState(false)
  const [musicPlayer, setMusicPlayer] = useState(null)
  const [commentCheck, setCommentCheck] = useState(false)
  var waveExists = false;
  useEffect(() => {
    if (!waveExists) {
      let wavesurfer = WaveSurfer.create({
        // container: '#waveform',
        container: musicRef.current,
        waveColor: '#FFFFFF',
        progressColor: '#F9480B',
        url: musicItem.musicFile,
        barWidth: 2,
        height: 140,
        splitChannels: false,
      })
      setMusicPlayer(wavesurfer)
      waveExists = true
    }
  }, [])
  useEffect(() => {
    if (commentCheck) {
      $(commentRef.current).slideDown()
    } else {
      $(commentRef.current).slideUp()
    }
  }, [commentCheck])
  const playMusic = () => {
    musicPlayer.playPause()
    setMusicPlayed(!musicPlayed)
    // setPlayingMusic(musicItem)
  }
  return (
    <>
      <div className="music-item" id={musicItem._id}>
        {musicItem.featured && <span className='featured'>{musicItem.featured}</span>}
        <Row gutter={30} style={{ alignItems: 'stretch' }}>
          <Col span={19}>
            <div className='music-info'>
              <div className="first">
                <button className='play-pause' onClick={playMusic}>
                  {musicPlayed ? <FaPause /> : <FaPlay />}
                </button>
                <h4 className="theme-h4">{musicItem.title}</h4>
                <ul>
                  <li>{musicItem.uploaded}</li>
                  <li>{musicItem.category}</li>
                </ul>
              </div>
              <div ref={musicRef} className="waveform"></div>
            </div>
          </Col>
          <Col span={5}>
            <img className='img-fluid artist-img' src={musicItem.artistImg} alt={musicItem.artist} />
          </Col>
        </Row>
        <Col span={19}>
          <div className="user-actions">
            <ul>
              <li className='checkbox-wrapper'>
                <input type="checkbox" name="like" />
                <label className='action-button'><SlLike /> <span>33k</span></label>
              </li>
              <li className='checkbox-wrapper'>
                <input
                  type="checkbox"
                  name="comment"
                  onChange={() => {
                    setCommentCheck(!commentCheck)
                  }}
                />
                <label className='action-button'><FaRegComment /> <span>15k</span></label>
              </li>
              <li><button className='action-button'><FiShare /> <span>Share</span></button></li>
            </ul>
            <ul>
              <li><a className='action-button' href={musicItem.musicFile} download={musicItem.title.replaceAll(' ', '-')}><FiDownload /> <span>Download</span></a></li>
            </ul>
          </div>
        </Col>
        <div className="comment-box" style={{ display: 'none' }} ref={commentRef}>
          <hr />
          <ul className='comment-list'>
            {musicItem.comments?.map((comment, ind) => {
              return (
                <li>
                  <div className="user-img">
                    <img src={comment.userImage} alt={comment.usetTitle} />
                  </div>
                  <ul>
                    <li className='user-title'>{comment.usetTitle}</li>
                    <li className='comment-text'>{comment.text}</li>
                  </ul>
                </li>
              )
            })}
          </ul>
          <div className="comment-field">
            <textarea placeholder='Leave a Comment Here'></textarea>
            <button>Submit</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default MusicItem
