import React, { useEffect, useState } from 'react'
import { PiMusicNoteSimpleDuotone } from 'react-icons/pi';
import MusicItem from './MusicItem';
import music1 from '../images/music/music-1.png'
import music2 from '../images/music/music-2.png'
import music3 from '../images/music/music-3.png'
import user1 from '../images/users/1.jpg'
import user2 from '../images/users/2.jpg'
import user3 from '../images/users/3.jpg'

// const musicData = [
//   {
//     title: 'PRAY FOR ME - Kendrick Lamar ft The Weeknd - Black Panther The Album - Aftermath Records',
//     category: 'Pop Rap',
//     uploaded: '4 months ago',    
//     artist: 'Post Malone',
//     artistImg: music1,
//     barWidth: 3,
//     barGap: 3,
//     musicFile: require('../music/music1.mp3'),
//     comments: [
//       {
//         userImage: user1,
//         usetTitle: 'Cameron Williamson',
//         text: 'Serious astronomy fanatic like a lot of us are, you can probably remember that one event in childhood that started you along this exciting hobby. 😉',
//         date: new Date('2023-10-30'),
//       },
//       {
//         userImage: user2,
//         usetTitle: 'Bessie Cooper',
//         text: 'Serious astronomy fanatic like.',
//         date: new Date('2023-10-30'),
//       },
//       {
//         userImage: user3,
//         usetTitle: 'Kathryn Murphy',
//         text: 'Serious astronomy fanatic like.',
//         date: new Date('2023-10-30'),
//       },
//     ]
//   },
//   {
//     title: 'BAD GUY - Billie Eilish - When We All Fall Asleep, Where Do We Go?',
//     category: 'Pop Trap',
//     uploaded: '3 months ago',
//     featured: 'Exclusive Only',
//     artist: 'Billie Eilish',
//     artistImg: music2,
//     barWidth: 3,
//     barGap: 3,
//     musicFile: require('../music/music1.mp3'),
//     comments: [
//       {
//         userImage: user1,
//         usetTitle: 'Cameron Williamson',
//         text: 'Serious astronomy fanatic like a lot of us are, you can probably remember that one event in childhood that started you along this exciting hobby.',
//         date: new Date('2023-10-30'),
//       },
//       {
//         userImage: user2,
//         usetTitle: 'Bessie Cooper',
//         text: 'Serious astronomy fanatic like.',
//         date: new Date('2023-10-30'),
//       },
//       {
//         userImage: user3,
//         usetTitle: 'Kathryn Murphy',
//         text: 'Serious astronomy fanatic like.',
//         date: new Date('2023-10-30'),
//       },
//     ]
//   },
//   {
//     title: 'GODZILLA - Eminem ft Juice World - Lyrical Lemonade',
//     category: 'Hip-Hop',
//     uploaded: '4 months ago',
//     artist: 'Billie Eilish',
//     artistImg: music3,
//     barWidth: 3,
//     barGap: 3,
//     // musicFile: require('../music/music1.mp3'),
//     musicFile: require('../music/sample4.aac'),
//     comments: [
//       {
//         userImage: user1,
//         usetTitle: 'Cameron Williamson',
//         text: 'Serious astronomy fanatic like a lot of us are, you can probably remember that one event in childhood that started you along this exciting hobby.',
//         date: new Date('2023-10-30'),
//       },
//       {
//         userImage: user2,
//         usetTitle: 'Bessie Cooper',
//         text: 'Serious astronomy fanatic like.',
//         date: new Date('2023-10-30'),
//       },
//       {
//         userImage: user3,
//         usetTitle: 'Kathryn Murphy',
//         text: 'Serious astronomy fanatic like.',
//         date: new Date('2023-10-30'),
//       },
//     ]
//   }
// ]
function Recording() {
  const [musicData, setMusicData] = useState([])
  const [loading, setLoading] = useState(true)
  const fetchMusics = async () => {
    setLoading(true)
    const res = await fetch('/api/music/fetch', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
    const resJson = await res.json()
    console.log('resJson', resJson)
    if (resJson.success) {
      
      setMusicData(resJson.musics.map((music) => ({
        title: music.title,
        category: 'Hip-Hop',
        uploaded: '4 months ago',
        artist: music.artist?.firstName + ' ' + music.artist?.lastName,
        artistImg: '' + music.thumbnail,
        barWidth: 3,
        barGap: 3,
        // musicFile: require('../music/music1.mp3'),
        musicFile:'' + music.file,
        // comments: music.comments
        // id: music._id,
        // thumbnail: <img className='table-thumbnail' src={'' + music.thumbnail} />,
        // title: music.title,
        // artist: music.artist?.firstName + ' ' + music.artist?.lastName,
        // album: music.album?.title,
        // releaseDate: music.releaseDate,
        // tags: music.tags,
        // file: <audio controls><source src={'' + music.file} /></audio>,
      })))
    }
    setLoading(false)
  }
  useEffect(()=>{
    fetchMusics()
  },[])
  return (
    <div>
      <section class="record artist_new">
        <div className="container">
          <h2 className='more_theme'>
            <span className="icon-after">
              <PiMusicNoteSimpleDuotone />
            </span>
            Listen Online
            <span className="icon-before">
              <PiMusicNoteSimpleDuotone />
            </span>
          </h2>
          <div className="music-listing">
            {musicData.map((musicItem) => {
              console.log('musicItem',musicItem)
              return (
                <MusicItem musicItem={musicItem} />
              )
            })}
          </div>

        </div>
      </section>
    </div>
  )
}

export default Recording
