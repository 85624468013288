import React, { useEffect, useState } from 'react';
import { Col, Drawer, Button, Row, notification, } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import UploadAvatar from './UploadAvatar';
const PlaylistUpdateForm = ({ open, setOpen, fetchData, obj, setObj }) => {
    const [api, contextHolder] = notification.useNotification();
    const [imgUrl, setImgUrl] = useState(obj.thumbnail)
    const openNotificationWithIcon = (type, title, text) => {
        api[type]({
            message: title,
            description: text,
        });
    };
    const [submitting, setSubmitting] = useState(false)
    const [formData, setFormData] = useState({
        id: obj._id,
        thumbnail: obj.thumbnail,
        title: obj.title
    })
    const onClose = () => {
        setOpen(false);
        setTimeout(() => {
            setObj(null)
        }, 500);
    };
    useEffect(() => {
        setFormData({ ...formData, thumbnail: imgUrl })
    }, [imgUrl])
    const handleSubmit = async (e) => {
        try {
            setSubmitting(true)
            e.preventDefault()
            let error = false
            // if (!formData.firstName) {
            //     error = true
            //     openNotificationWithIcon('error', 'Validation Error', 'First Name is Required')
            // }
            if (!error) {
                const res = await fetch('/api/playlist/update', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(formData)
                })
                const resJson = await res.json()
                if (resJson.success) {
                    openNotificationWithIcon('success', 'Success', `Playlist Item has Updated!`)
                    setSubmitting(false)
                    setFormData({
                        id: obj._id,
                        title: '',
                        thumbnail: '',
                    })
                    setSubmitting(false)
                    setOpen(false)
                    fetchData()
                    setTimeout(() => {
                        setObj(null)
                    }, 500);
                } else {
                    setSubmitting(false)
                    openNotificationWithIcon('error', 'Error', resJson.error)
                }
            }
            setSubmitting(false)
        } catch (error) {
            openNotificationWithIcon('error', 'Validation Error', error.message)
            setSubmitting(false)
        }

    }
    return (
        <>
            {contextHolder}
            <Drawer
                className='admin-form-drawer'
                title="Update Playlist Item"
                width={520}
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <div className="admin-form">
                    <form onSubmit={handleSubmit}>
                        <Row gutter={[{ xl: 20 }, { xl: 20 }]}>
                            <Col xl={24}>
                                <div className="input-field image-upload">
                                    <label>Thumbnail</label>
                                    <UploadAvatar imageUrl={imgUrl} setImageUrl={setImgUrl} />
                                </div>
                            </Col>
                            <Col xl={24}>
                                <div className="input-field">
                                    <label>Title</label>
                                    <input value={formData.title} onChange={(e) => { setFormData({ ...formData, title: e.target.value }) }} type="text" />
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div className="input-field">
                                    <Button disabled={submitting} type="primary" size='large' htmlType='submit'>
                                        {submitting ? <LoadingOutlined /> : <></>}
                                        Update
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>
            </Drawer>
        </>
    );
}

export default PlaylistUpdateForm