import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import Silder from '../images/silder-1.png'
import silder_1 from '../images/silder-2.png'
import silder_2 from '../images/silder-3.png'
import silder_3 from '../images/silder-4.png'
import { PiMusicNoteSimpleDuotone } from 'react-icons/pi';
import { FaRegDotCircle } from "react-icons/fa";
import 'swiper/css';
import { FaPlay } from "react-icons/fa";

import 'swiper/css/pagination';
import { GoDot } from "react-icons/go";
import { Link } from 'react-router-dom';
// import { Pagination } from 'swiper/modules';

function Artist({ slider = true }) {
  const [loading, setLoading] = useState([])
  const [artists, setArtists] = useState([])
  const fetchArtists = async () => {
    setLoading(true)
    const res = await fetch('/api/artist/fetch', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
    const resJson = await res.json()
    console.log('resJson', resJson)
    if (resJson.success) {
      setArtists(resJson.artists)
    }
    setLoading(false)
  }
  useEffect(() => {
    fetchArtists()
  }, [])
  return (
    <div>
      <section className="artist_new">
        <div className="container">
          <h2 className='more_theme'>
            <span className="icon-after">
              <PiMusicNoteSimpleDuotone />
            </span>
            Featured Artist
            <span className="icon-before">
              <PiMusicNoteSimpleDuotone />
            </span>

          </h2>
          <Row style={{ marginTop: '25px' }}>
            <Col span={24}>
              {slider ? <Swiper
                slidesPerView={3.5}
                spaceBetween={30}
                // pagination={{
                //   clickable: false,
                // }}
                // modules={[Pagination]}
                className="mySwiper"
              >
                {artists.map((artist, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="custom_silder">
                        <h2><FaRegDotCircle />{artist.firstName} {artist.lastName}</h2>
                        <img src={'' + artist.avatar} className='img-fluid' alt="img" />
                        <ul className="flex-btn-1">
                          {artist.tags.map((tag, ind) => <li key={ind}><GoDot />{tag}</li>)}
                        </ul>
                      </div>
                      <div className="icon-roper">
                        <Link to={'/artist/' + artist._id}>Listen Now <span><FaPlay /></span></Link>
                      </div>
                    </SwiperSlide>
                  )
                })}

              </Swiper> : <>
                <Row gutter={[{ xl: 20 }, { xl: 20 }]}>
                  {artists.map((artist, index) => {
                    return (<Col xl={8} key={index}>
                      <div className="custom_silder">
                        <h2><FaRegDotCircle />{artist.firstName} {artist.lastName}</h2>
                        <img src={'' + artist.avatar} className='img-fluid' alt="img" />
                        <ul className="flex-btn-1">
                          {artist.tags.map((tag, ind) => <li key={ind}><GoDot />{tag}</li>)}
                        </ul>
                      </div>
                      <div className="icon-roper">
                        <Link to={"/artist/" + artist._id}>Listen Now <span><FaPlay /></span></Link>
                      </div>
                    </Col>)
                  })}
                </Row>
              </>}
            </Col>
          </Row>
        </div>
      </section>
    </div>
  )
}

export default Artist
