import { Col, Row } from 'antd'
import React from 'react'
import footerLft from '../images/footer-lft.png'
import { Link } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa'
import { MdCall } from "react-icons/md";
import logo from '../images/logo.png'

const Footer = () => {
    return (
        <>
            <footer className='footer-main'>
                <Row style={{alignItems: 'stretch'}} gutter={7}>
                    <Col lg={8}>
                        <img className='footer-img' src={footerLft} alt="" />
                    </Col>
                    <Col lg={8}>
                        <div className="footer-mdl">
                            <h2>Join us <br />mizzleboy</h2>
                            <h2>Revolution</h2>
                            <Link className='arrow-contact' to="/contact"><FaArrowRight /></Link>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="footer-rgt">
                            <a className='need-help' href="tel:123456789">Need Any Help <span className="icon"><MdCall /></span></a>
                            <img className='footer-logo' src={logo} alt="Clarke's Music Services" />
                            <Link className='contact-link' to='/contact'>Contact Us <span className="icon"><FaArrowRight /></span></Link>
                        </div>
                    </Col>
                </Row>
            </footer>
        </>
    )
}

export default Footer
