import React from 'react'
import InnerBanner from '../components/InnerBanner'
import AboutComp from '../components/About'
import Services from '../components/Services'
import SubscribedToUpdate from '../components/SubscribedToUpdate'
import ChangingTheGame from '../components/ChangingTheGame'

const About = () => {
    return (
        <>
            <InnerBanner title='About Us' />
            <Services />
            <AboutComp />
            <ChangingTheGame />
            <SubscribedToUpdate />
        </>
    )
}

export default About
