import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BsFillPlayFill } from 'react-icons/bs';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import 'swiper/css/scrollbar';
import 'swiper/css/scrollbar';
import { Mousewheel, Navigation } from 'swiper/modules'
import { PiMusicNoteSimpleDuotone } from 'react-icons/pi';
import { Col, Row } from 'antd';
import Inner1 from '../images/inner-1.png';
import Inner2 from '../images/inner-2.png';
import Inner3 from '../images/inner-3.png';
import Inner4 from '../images/inner-4.png';
import Inner5 from '../images/inner-5.png';
import Inner6 from '../images/inner-6.png';
import Inner7 from '../images/inner-7.png';
import Inner8 from '../images/inner-8.png';
import box_1 from '../images/four-box.png';
import box_2 from '../images/first-box.png';
import box_3 from '../images/third-box.png';
import box_4 from '../images/end-box.png';
import { Link } from 'react-router-dom';

function Music_community() {
    return (
        <div>
            <section className="Unity Featured_Albums">
                <div className="container">
                    <div className="feature_text intro_1">
                        <h2>
                            <span className="icon-after">
                                <PiMusicNoteSimpleDuotone />
                            </span>
                            Music Community
                            <span className="icon-before">
                                <PiMusicNoteSimpleDuotone />
                            </span>
                        </h2>
                    </div>
                    <div className="paragraph">
                        <p className="invoke">
                            Step into a world of immersive entertainment. Our "Music Community” serves as the gateway to limitless enjoyment, where rhythm meets sounds to create an unparalleled entertainment journey.
                        </p>
                    </div>
                    <Row gutter={[60, 50]} style={{}}>
                        <Col span={3}>
                        <h3 className='opera'>Recent Listners</h3>
                            <div className="getter">
                               
                                <Swiper
                                    // navigation={{
                                    //     nextEl: nextRef.current
                                    // }}
                                    navigation={true}                                    
                                    direction={'vertical'}
                                    centeredSlides={true}
                                    // allowTouchMove={false}
                                    onScrollbarDragStart={false}
                                    allowSlidePrev={false}
                                    loop={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    mousewheel={true}
                                    modules={[

                                        Navigation, 
                                        Mousewheel
                                    ]}
                                    pagination={{
                                        clickable: false,
                                        navigation: true,
                                    }}
                                    slidesPerView={8}
                                    spaceBetween={8}
                                    className="mySwiper"
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 6,
                                            spaceBetween: 10,
                                            mousewheel: false,
                                        },
                                        640: {
                                            slidesPerView:6.5,
                                            spaceBetween: 30,
                                        },
                                        768: {
                                            slidesPerView: 8.5,
                                            spaceBetween: 80,
                                        },
                                        1024: {
                                            slidesPerView: 8.5,
                                            spaceBetween: 80,
                                        },
                                    }}
                                >
                                    <SwiperSlide>
                                        <img src={Inner1} className="img-fluid" alt="img" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={Inner2} className="img-fluid" alt="img" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={Inner3} className="img-fluid" alt="img" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={Inner4} className="img-fluid" alt="img" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={Inner5} className="img-fluid" alt="img" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={Inner6} className="img-fluid" alt="img" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={Inner7} className="img-fluid" alt="img" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={Inner8} className="img-fluid" alt="img" />
                                    </SwiperSlide>
                                </Swiper>
                                
                            </div>
                        </Col>
                        <Col span={21}>
                            <div className="main-four">
                                <Row gutter={[16, 40]} style={{ background: '#151515', padding: '25px' ,borderRadius: '25px' }}>
                                    <Col span={12}>
                                        <div className="custom-box" style={{ backgroundImage: `url(${box_4})` }}>
                                            <div className="stick">
                                                <h4>Pray For Me</h4>
                                                <p>Weeknd x Kendrick Lamar</p>
                                                <div className="sticky">
                                                    <div className="sticky_icon">
                                                        <Link to="/">
                                                            <BsFillPlayFill />
                                                        </Link>
                                                    </div>
                                                    <div className="sticy-text">
                                                        <p>19.2k Listening</p>
                                                        <p>300k Streams</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="custom-box" style={{ backgroundImage: `url(${box_2})` }}>
                                            <div className="stick">
                                                <h4>Pray For Me</h4>
                                                <p>Weeknd x Kendrick Lamar</p>
                                                <div className="sticky">
                                                    <div className="sticky_icon">
                                                        <Link to="/">
                                                            <BsFillPlayFill />
                                                        </Link>
                                                    </div>
                                                    <div className="sticy-text">
                                                        <p>19.2k Listening</p>
                                                        <p>300k Streams</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="custom-box" style={{ backgroundImage: `url(${box_3})` }}>
                                            <div className="stick">
                                                <h4>Pray For Me</h4>
                                                <p>Weeknd x Kendrick Lamar</p>
                                                <div className="sticky">
                                                    <div className="sticky_icon">
                                                        <Link to="/">
                                                            <BsFillPlayFill />
                                                        </Link>
                                                    </div>
                                                    <div className="sticy-text">
                                                        <p>19.2k Listening</p>
                                                        <p>300k Streams</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="custom-box" style={{ backgroundImage: `url(${box_1})` }}>
                                            <div className="stick">
                                                <h4>Pray For Me</h4>
                                                <p>Weeknd x Kendrick Lamar</p>
                                                <div className="sticky">
                                                    <div className="sticky_icon">
                                                        <Link to="/">
                                                            <BsFillPlayFill />
                                                        </Link>
                                                    </div>
                                                    <div className="sticy-text">
                                                        <p>19.2k Listening</p>
                                                        <p>300k Streams</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    );
}

export default Music_community;
