import { Col, Row, notification } from 'antd'
import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import UploadAvatar from './admin/UploadAvatar'
import Cookies from "js-cookie";
import { UserDataContext } from '../context/User';

const RegisterForm = () => {
    const { setAuthToken } = useContext(UserDataContext)
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message,
            description
        });
    };
    const [formData, setFormData] = useState({
        profilePicture: '',
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        password: '',
    })
    const [profilePicture, setProfilePicture] = useState('')
    useEffect(() => {
        setFormData({ ...formData, profilePicture })
    }, [profilePicture])
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormData({ ...formData, [name]: value })
    }
    const handleSubmit = async (e) => {
        const { profilePicture, firstName, email, confirmEmail, password } = formData
        let error = false
        e.preventDefault()
        if (!firstName) {
            openNotificationWithIcon('error', 'Validation Error', 'First Name is Required')
            error = true;
        }
        if (!email) {
            openNotificationWithIcon('error', 'Validation Error', 'Email is Required')
            error = true;
        }
        if (password.length < 6) {
            openNotificationWithIcon('error', 'Validation Error', 'Password Should have 6 or more characters')
            error = true;
        }
        if (email && email !== confirmEmail) {
            openNotificationWithIcon('error', 'Validation Error', 'Email and Confirm Email not Matched!')
            error = true;
        }
        if (!error) {
            const res = await fetch('/api/auth/register', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            })
            const resJson = await res.json()
            console.log('resJson', resJson)
            if (resJson.success) {
                setAuthToken(resJson.authtoken)
                openNotificationWithIcon('success', 'Success', 'User Successfully Registered')
                Cookies.set("authtoken", resJson.authtoken);
            } else {
                openNotificationWithIcon('error', 'Failed To Register', resJson.error)
            }
        }
    }
    return (
        <section className='register-form'>
            {contextHolder}
            <div className="container">
                <Row style={{ justifyContent: 'center' }}>
                    <Col xl={16}>
                        <form onSubmit={handleSubmit}>
                            <div className="form-elem">
                                <h3 className="sec-title">Sign Up to Clarke’s Music Services</h3>
                                {/* <img className='img-fluid' src={contactImg} alt="" /> */}
                                <Row gutter={[{ xl: 20 }, { xl: 20 }]}>
                                    <Col xs={24}>
                                        <div className="profile-img-upload input-field">
                                            <label htmlFor="">Profile Picture</label>
                                            <UploadAvatar imageUrl={profilePicture} setImageUrl={setProfilePicture} />
                                        </div>
                                    </Col>
                                    <Col xl={12} xs={24}>
                                        <div className="input-field">
                                            <label htmlFor="">First Name</label>
                                            <input name='firstName' value={formData.firstName} onChange={handleChange} placeholder='First Name' type="text" required />
                                        </div>
                                    </Col>
                                    <Col xl={12} xs={24}>
                                        <div className="input-field">
                                            <label htmlFor="">Last Name</label>
                                            <input name='lastName' value={formData.lastName} onChange={handleChange} placeholder='Last Name' type="text" required />
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className="input-field">
                                            <label htmlFor="">Email</label>
                                            <input name='email' value={formData.email} onChange={handleChange} placeholder='Email' type="email" required />
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className="input-field">
                                            <label htmlFor="">Confirm Email</label>
                                            <input name='confirmEmail' value={formData.confirmEmail} onChange={handleChange} placeholder='Confirm Your Email' type="email" required />
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className="input-field">
                                            <label htmlFor="">Password</label>
                                            <input name='password' value={formData.password} onChange={handleChange} type="password" />
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className="form-link">
                                            <p className="theme-p">Already have an Account? <Link to='/login'>LogIn</Link></p>
                                        </div>
                                    </Col>
                                </Row>
                                <button type='submit' className="theme-btn btn-white">Register</button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default RegisterForm
