import React from 'react'
import error404 from  '../images/404.png'
const Error404 = () => {
  return (
    <section className='error404'>
      <div className="container">
        <img className='img-fluid' src={error404} alt="404" />
      </div>
    </section>
  )
}

export default Error404
