import { Col, Row, notification } from 'antd'
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { UserDataContext } from '../context/User';

const LogInForm = () => {
    const { setAuthToken } = useContext(UserDataContext)
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message,
            description
        });
    };
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormData({ ...formData, [name]: value })
    }
    const handleSubmit = async (e) => {
        const { email, password } = formData
        let error = false
        e.preventDefault()
        if (!email) {
            openNotificationWithIcon('error', 'Validation Error', 'Email is Required')
            error = true;
        }
        if (!password) {
            openNotificationWithIcon('error', 'Validation Error', 'Password is Required')
            error = true;
        }
        if (!error) {
            const res = await fetch('/api/auth/login', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            })
            const resJson = await res.json()
            console.log('resJson', resJson)
            if (resJson.success) {
                setAuthToken(resJson.authtoken)
                openNotificationWithIcon('success', 'Success', 'User Successfully Logged In')
                Cookies.set("authtoken", resJson.authtoken);
            } else {
                openNotificationWithIcon('error', 'Failed To Register', resJson.error)
            }
        }
    }
    return (
        <section className='register-form'>
            {contextHolder}
            <div className="container">
                <Row style={{ justifyContent: 'center' }}>
                    <Col xl={16}>
                        <form onSubmit={handleSubmit}>
                        <div className="form-elem">
                            <h3 className="sec-title">Log In to Clarke’s Music Services</h3>
                            {/* <img className='img-fluid' src={contactImg} alt="" /> */}
                            <Row gutter={[{ xl: 20 }, { xl: 20 }]}>
                                <Col xs={24}>
                                    <div className="input-field">
                                        <label htmlFor="">Email</label>
                                        <input name='email' value={formData.email} onChange={handleChange} placeholder='Email' type="email" />
                                    </div>
                                </Col>
                                <Col xs={24}>
                                    <div className="input-field">
                                        <label htmlFor="">Password</label>
                                        <input name='password' value={formData.password} onChange={handleChange} type="password" />
                                    </div>
                                </Col>
                                <Col xs={24}>
                                    <div className="form-link">
                                        <p className="theme-p">Don't have an Account? <Link to='/register'>Register</Link></p>
                                    </div>
                                </Col>
                            </Row>
                            <button type='submit' className="theme-btn btn-white">Log In</button>
                        </div>
                        </form>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default LogInForm
