import React, { useEffect, useState } from 'react';
import { Col, Drawer, Button, Row, notification, Upload,DatePicker } from 'antd';
import TagInput from './TagInput';
import { LoadingOutlined, InboxOutlined } from '@ant-design/icons';
import UploadAvatar from './UploadAvatar';
const MusicUpdateFormDrawer = ({ open, setOpen, fetchData, obj, setObj }) => {
    const { Dragger } = Upload;
    const [artists, setArtists] = useState([])
    const [albums, setAlbums] = useState([])
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, text) => {
        api[type]({
            message: title,
            description: text,
        });
    };
    const [submitting, setSubmitting] = useState(false)
    const [formData, setFormData] = useState({
        id: obj._id,
        thumbnail: obj.thumbnail,
        title: obj.title,
        album: obj.album,
        releaseDate: obj.releaseDate,
        artist: obj.artist,
        file: obj.file,
        tags: obj.tags
    })
    const [imgUrl, setImgUrl] = useState(obj.thumbnail)
    const [tags, setTags] = useState(obj.tags)
    const onClose = () => {
        setOpen(false);
        setTimeout(() => {
            setObj(null)
        }, 500);
    };
    useEffect(() => {
        setFormData({ ...formData, thumbnail: imgUrl, tags })
        console.log('tags', tags)
        console.log('imgUrl', imgUrl)
    }, [tags, imgUrl])
    const fetchArtists = async () => {
        const res = await fetch('/api/artist/fetch', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const resJson = await res.json()
        console.log('resJson', resJson)
        if (resJson.success) {
            setArtists(resJson.artists)
        }
    }
    useEffect(() => {
        fetchArtists()
    }, [])
    const fetchAlbums = async () => {
        const res = await fetch('/api/album/fetchbyartist', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ artist: formData.artist })
        })
        const resJson = await res.json()
        console.log('resJson', resJson)
        if (resJson.success) {
            setAlbums(resJson.albums)
        }
    }
    useEffect(() => {
        if (formData.artist) {
            fetchAlbums()
        }
    }, [formData.artist])
    const handleDate = (date, dateString) => {
        console.log(date, dateString);
        setFormData({ ...formData, releaseDate: new Date(date) })
    };
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            
            // info.file.response.filename
            setFormData({ ...formData, file: '/media/audios/' + info.file.response.filename })
        }
    };
    const handleSubmit = async (e) => {
        try {
            setSubmitting(true)
            e.preventDefault()
            let error = false
            // if (!formData.firstName) {
            //     error = true
            //     openNotificationWithIcon('error', 'Validation Error', 'First Name is Required')
            // }
            if (!error) {
                const res = await fetch('/api/music/update', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(formData)
                })
                const resJson = await res.json()
                if (resJson.success) {
                    openNotificationWithIcon('success', 'Success', `Music Item has Updated!`)
                    setSubmitting(false)
                    setFormData({
                        title: '',
                        artist: '',
                        thumbnail: '',
                        tags: []
                    })
                    setTags([])
                    setImgUrl('')
                    setSubmitting(false)
                    setOpen(false)
                    fetchData()
                    setTimeout(() => {
                        setObj(null)
                    }, 500);
                } else {
                    setSubmitting(false)
                    openNotificationWithIcon('error', 'Error', resJson.error)
                }
            }
            setSubmitting(false)
        } catch (error) {
            openNotificationWithIcon('error', 'Validation Error', error.message)
            setSubmitting(false)
        }

    }
    return (
        <>
            {contextHolder}
            <Drawer
                className='admin-form-drawer'
                title="Update Music Item"
                width={520}
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <div className="admin-form">
                    <form onSubmit={handleSubmit}>
                        <Row gutter={[{ xl: 20 }, { xl: 20 }]}>
                            <Col xl={24}>
                                <div className="input-field image-upload">
                                    <label>Thumbnail</label>
                                    <UploadAvatar imageUrl={imgUrl} setImageUrl={setImgUrl} />
                                </div>
                            </Col>
                            <Col xl={24}>
                                <div className="input-field">
                                    <label>Title</label>
                                    <input value={formData.title} onChange={(e) => { setFormData({ ...formData, title: e.target.value }) }} type="text" />
                                </div>
                            </Col>
                            <Col xl={24}>
                                <div className="input-field">
                                    <label>Artist</label>
                                    <select value={formData.artist} onChange={(e) => { setFormData({ ...formData, artist: e.target.value }) }}>
                                        <option selected>Select Artist</option>
                                        {artists.map((artist, index) => {
                                            return <option key={index} value={artist._id}>{artist.firstName} {artist.lastName}</option>
                                        })}
                                    </select>
                                </div>
                            </Col>
                            <Col xl={24}>
                                <div className="input-field">
                                    <label>Album</label>
                                    <select value={formData.album} onChange={(e) => { setFormData({ ...formData, album: e.target.value }) }}>
                                        <option selected>Select Album</option>
                                        {albums.map((album, index) => {
                                            return <option key={index} value={album._id}>{album.title}</option>
                                        })}
                                    </select>
                                </div>
                            </Col>
                            <Col xl={24}>
                                <div className="input-field">
                                    <label>Released Date</label>
                                    <DatePicker className="w-100" onChange={handleDate} needConfirm />
                                </div>
                            </Col>
                            <Col xl={24}>
                                <div className="input-field">
                                    <label>Tags</label>
                                    <TagInput tags={tags} setTags={setTags} />
                                </div>
                            </Col>
                            <Col xl={24}>
                                <div className="input-field">
                                    <label>Music File</label>
                                    <Dragger
                                        name='audio'
                                        action='/api/upload/audio'
                                        multiple={false}
                                        onChange={handleChange}
                                        maxCount={1}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="theme-p">Click or drag file to this area to upload</p>
                                        <p className="theme-sm">
                                            Upload Music File in mpeg / mp3 / wav / x-wav / flac / aac / mp4 / x-m4a format
                                        </p>
                                    </Dragger>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div className="input-field">
                                    <Button disabled={submitting} type="primary" size='large' htmlType='submit'>
                                        {submitting ? <LoadingOutlined /> : <></>}
                                        Submit
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>
            </Drawer>
        </>
    );
}

export default MusicUpdateFormDrawer