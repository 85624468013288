import { Col, Row } from 'antd';
import React from 'react'
import { PiMusicNoteSimpleDuotone } from 'react-icons/pi';
import MoodCard from './MoodCard';
// images
import mood1 from '../images/mood/1.png'
import mood2 from '../images/mood/2.png'
import mood3 from '../images/mood/3.png'
import mood4 from '../images/mood/4.png'
import mood5 from '../images/mood/5.png'
import mood6 from '../images/mood/6.png'
import mood7 from '../images/mood/7.png'
import mood8 from '../images/mood/8.png'
const MusicMood = () => {
    const moodData = [
        {
            title: 'Top Rated',
            image: mood1
        },
        {
            title: 'Top Rated',
            image: mood2
        },
        {
            title: 'Top Rated',
            image: mood3
        },
        {
            title: 'Top Rated',
            image: mood4
        },
        {
            title: 'Top Rated',
            image: mood5
        },
        {
            title: 'Top Rated',
            image: mood6
        },
        {
            title: 'Top Rated',
            image: mood7
        },
        {
            title: 'Top Rated',
            image: mood8
        }
    ]
    return (
        <>
            <section className="music-mood">
                <div className="container">
                    <div className="heading-icons">
                        <h2>
                            <span className="icon-after">
                                <PiMusicNoteSimpleDuotone />
                            </span>
                            Music Community
                            <span className="icon-before">
                                <PiMusicNoteSimpleDuotone />
                            </span>
                        </h2>
                    </div>
                    <div className="content-wrapper">
                        <h3 className="theme-h3">Based on your mood</h3>
                        <div className="mood-listing">
                        <Row gutter={[80, 50]}>
                            {moodData.map((data, index) => {
                                return (
                                    <Col span={6}>
                                        <MoodCard data={data} key={index} />
                                    </Col>
                                )
                            })}
                        </Row>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MusicMood
