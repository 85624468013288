import React from 'react'
import InnerBanner from '../components/InnerBanner'
import RegisterForm from '../components/RegisterForm'
import SubscribedToUpdate from '../components/SubscribedToUpdate'

const Register = () => {
  return (
    <>
     <InnerBanner title='Register' />
     <RegisterForm />
     <SubscribedToUpdate />
    </>
  )
}

export default Register
