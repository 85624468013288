import React from 'react'
import { Col, Row } from 'antd';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import First_box from '../images/first_box.png'
import banner12 from '../images/banner52.png'
import { GoDotFill } from "react-icons/go";
import second_box from '../images/second-banner.png'
import third_box from '../images/third-box3.png'
import fourth from '../images/forth.png'







function Listing_option() {
    return (
        <div>
            <section className='Listing_options'>
                <div className="container">
                    <div className="income">
                        <Row gutter={[50, 1]} style={{
                             position: 'relative', 
                             zIndex: 1,
                             padding: '60px 0px', borderRadius: '25px', overflow: 'hidden' }}>
                            <Col span={12} >
                                <div className="bottel">
                                    <h2>CLARKE’S MUSIC SERVICES
                                        provides you with best listening options</h2>
                                    <p>It is important to understand that music can change your mood and make your better where you are depressed or feeling low.</p>
                                </div>
                            </Col>
                            <Col span={12}>
                                <Row gutter={[15, 25]}>
                                    <Col span={12}>
                                        <div className="box_pink">
                                            <img src={First_box} className='img-fluid' alt="img" />
                                            <h3>01</h3>
                                            <h5>Essential Access</h5>
                                            <h6>Features:</h6>
                                            <ul>
                                                <li> <span>< GoDotFill /></span>Ad-supported streaming with occasional commercial.
                                                </li>
                                                <li>
                                                <span>< GoDotFill /></span> Access to a limited music library.
                                                </li>
                                                <li>
                                                <span>< GoDotFill /></span> Basic customization options (playlist creation, limited skips).</li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="box_pink yellow-box">
                                            <img src={second_box} className='img-fluid' alt="img" />
                                            <h3>02</h3>
                                            <h5>Music Lover</h5>
                                            <h6>Features:</h6>
                                            <ul>
                                                <li><span>< GoDotFill /></span> Ad-supported streaming with occasional commercial.
                                                </li>
                                                <li>
                                                <span>< GoDotFill /></span> Access to a limited music library.
                                                </li>
                                                <li>
                                                <span>< GoDotFill /></span> Basic customization options (playlist creation, limited skips).</li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="box_pink yellow-box">
                                            <img src={third_box} className='img-fluid' alt="img" />
                                            <h3>03</h3>
                                            <h5>Premium Experience</h5>
                                            <h6>Features:</h6>
                                            <ul>
                                                <li><span>< GoDotFill /></span> Ad-supported streaming with occasional commercial.
                                                </li>
                                                <li>
                                                <span>< GoDotFill /></span>Access to a limited music library.
                                                </li>
                                                <li>
                                                <span>< GoDotFill /></span> Basic customization options (playlist creation, limited skips).</li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="box_pink ">
                                            <img src={fourth} className='img-fluid' alt="img" />
                                            <h3>04</h3>
                                            <h5>Family Harmony</h5>
                                            <h6>Features:</h6>
                                            <ul>
                                                <li><span>< GoDotFill /></span> Ad-supported streaming with occasional commercial.
                                                </li>
                                                <li>
                                                <span>< GoDotFill /></span> Access to a limited music library.
                                                </li>
                                                <li>
                                                <span>< GoDotFill /></span> Basic customization options (playlist creation, limited skips).</li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        
                        <div className="logistic-20">
                                <img src={banner12} className='img-fluid' alt="img" />
                            </div>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Listing_option
