import React from 'react'
import InnerBanner from '../components/InnerBanner'
import ContactForm from '../components/ContactForm'
import SubscribedToUpdate from '../components/SubscribedToUpdate'
import ChangingTheGame from '../components/ChangingTheGame'

const Contact = () => {
  return (
    <>
     <InnerBanner title='Contact Us' />
     <ContactForm />
     <ChangingTheGame />
     <SubscribedToUpdate />
    </>
  )
}

export default Contact
