import React, { useEffect, useState } from 'react';
import { Col, Drawer, Button, Row, notification } from 'antd';
import TagInput from './TagInput';
import { LoadingOutlined } from '@ant-design/icons';
import UploadAvatar from './UploadAvatar';
const ArtistUpdateFormDrawer = ({ open, setOpen, fetchData, obj, setObj }) => {
    console.log('obj', obj)
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, text) => {
        api[type]({
            message: title,
            description: text,
        });
    };
    const [submitting, setSubmitting] = useState(false)
    const [formData, setFormData] = useState({
        id: obj._id,
        firstName: obj.firstName,
        lastName: obj.lastName,
        avatar: obj.avatar,
        tags: obj ? obj.tags : []
    })
    const [imgUrl, setImgUrl] = useState(obj.avatar)
    const [tags, setTags] = useState(obj.tags)
    const onClose = () => {
        setOpen(false);
        setTimeout(() => {
            setObj(null)
        }, 500);
    };
    useEffect(() => {
        setFormData({ ...formData, avatar: imgUrl, tags })
        console.log('tags', tags)
        console.log('imgUrl', imgUrl)
    }, [tags, imgUrl])
    const handleSubmit = async (e) => {
        try {
            setSubmitting(true)
            e.preventDefault()
            let error = false
            if (!formData.firstName) {
                error = true
                openNotificationWithIcon('error', 'Validation Error', 'First Name is Required')
            }
            // if (!formData.lastName) {
            //     error = true
            //     openNotificationWithIcon('error', 'Validation Error', 'Last Name is Required')
            // }
            if (formData.tags < 1) {
                error = true
                openNotificationWithIcon('error', 'Validation Error', 'Tags are Required')
            }
            if (!formData.avatar) {
                error = true
                openNotificationWithIcon('error', 'Validation Error', 'Profile Picture is Required')
            }
            if (!error) {
                const res = await fetch('/api/artist/update', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(formData)
                })
                const resJson = await res.json()
                if (resJson.success) {
                    openNotificationWithIcon('success', 'Success', `Artist has been updated!`)
                    setSubmitting(false)
                    setFormData({
                        firstName: '',
                        lastName: '',
                        avatar: '',
                        tags: []
                    })
                    setTags([])
                    setImgUrl('')
                    setSubmitting(false)
                    setOpen(false)
                    fetchData()
                } else {
                    setSubmitting(false)
                    openNotificationWithIcon('error', 'Error', resJson.error)
                }
            }
            setSubmitting(false)
        } catch (error) {
            openNotificationWithIcon('error', 'Validation Error', error.message)
            setSubmitting(false)
        }

    }
    return (
        <>
            {contextHolder}
            <Drawer
                className='admin-form-drawer'
                title="Update Artist Data"
                width={520}
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <div className="admin-form">
                    <form onSubmit={handleSubmit}>
                        <Row gutter={[{ xl: 20 }, { xl: 20 }]}>
                            <Col xl={24}>
                                <div className="input-field image-upload">
                                    <label>Profile Picture</label>
                                    <UploadAvatar imageUrl={imgUrl} setImageUrl={setImgUrl} />
                                </div>
                            </Col>
                            <Col xl={24}>
                                <div className="input-field">
                                    <label>First Name</label>
                                    <input value={formData.firstName} onChange={(e) => { setFormData({ ...formData, firstName: e.target.value }) }} type="text" />
                                </div>
                            </Col>
                            <Col xl={24}>
                                <div className="input-field">
                                    <label>Last Name</label>
                                    <input value={formData.lastName} onChange={(e) => { setFormData({ ...formData, lastName: e.target.value }) }} type="text" />
                                </div>
                            </Col>
                            <Col xl={24}>
                                <div className="input-field">
                                    <label>Tags</label>
                                    <TagInput tags={tags} setTags={setTags} />
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div className="input-field">
                                    <Button disabled={submitting} type="primary" size='large' htmlType='submit'>
                                        {submitting ? <LoadingOutlined /> : <></>}
                                        Update
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>
            </Drawer>
        </>
    );
};
export default ArtistUpdateFormDrawer;