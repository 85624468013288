import React from 'react'
import InnerBanner from '../components/InnerBanner'
import Artist from '../components/Artist'

const FeaturedArtists = () => {
    return (
        <>
            <InnerBanner title='featured artists' />
            <Artist slider={false} />
            {/* <div className="load-more">
                <button className="theme-btn btn-outline">Explore More</button>
            </div> */}
        </>
    )
}

export default FeaturedArtists
