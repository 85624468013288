import React from 'react'
import { Link } from 'react-router-dom'

const PlaylistCard = ({ data }) => {
    return (
        <>
            <Link className="playlist-card" to={"/playlist/" + data._id}>
                <img className='img-fluid' src={'' + data.thumbnail} alt={data.title} />
                <h5 className="theme-h5">{data.title}</h5>
                <p className="theme-p">{data.detail}</p>
            </Link>
        </>
    )
}

export default PlaylistCard
