import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import InnerBanner from '../components/InnerBanner'
import MusicItem from '../components/MusicItem';
import { PiMusicNoteSimpleDuotone } from 'react-icons/pi';
const ArtistInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true)
    const [artist, setArtist] = useState(null)
    const [musics, setMusics] = useState(null)
    const fetchDetails = async () => {
        console.log('userId', id)
        const response = await fetch(`/api/artist/${id}`, {
            method: 'POST'
        })
        const result = await response.json()
        const { success, artist, music, error } = result
        if (success) {
            setLoading(false)
            setArtist(artist)
            setMusics(music.map((music) => ({
                title: music.title,
                category: 'Hip-Hop',
                uploaded: '4 months ago',
                artist: music.artist?.firstName + ' ' + music.artist?.lastName,
                artistImg: '' + music.thumbnail,
                barWidth: 3,
                barGap: 3,
                // musicFile: require('../music/music1.mp3'),
                musicFile:'' + music.file,
                // comments: music.comments
                // id: music._id,
                // thumbnail: <img className='table-thumbnail' src={'' + music.thumbnail} />,
                // title: music.title,
                // artist: music.artist?.firstName + ' ' + music.artist?.lastName,
                // album: music.album?.title,
                // releaseDate: music.releaseDate,
                // tags: music.tags,
                // file: <audio controls><source src={'' + music.file} /></audio>,
              })))
        }
        console.log('result', result)
    }
    useEffect(() => {
        if (id) {
            fetchDetails()
        }
    }, [])
    useEffect(() => {
        console.log('artist', artist)
        console.log('musics', musics)
    }, [artist,musics])
    return (
        <div>
            {!loading ? <>
                <InnerBanner title={artist.firstName + ' ' + artist.lastName} />
                <section class="record artist_new">
                    <div className="container">
                        <h2 className='more_theme'>
                            <span className="icon-after">
                                <PiMusicNoteSimpleDuotone />
                            </span>
                            Listen Online
                            <span className="icon-before">
                                <PiMusicNoteSimpleDuotone />
                            </span>
                        </h2>
                        <div className="music-listing">
                            {musics.length > 0 ? <>
                                {musics?.map((musicItem) => {
                                    return (
                                        <MusicItem musicItem={musicItem} />
                                    )
                                })}
                            </> : <>
                            <h5 className="theme-h2 text-center">Music Not Found!</h5>
                            </>}
                        </div>
                    </div>
                </section>
            </> : <></>}
            {/* {loading ? <></> 
                // <InnerBanner title={artist.firstName + ' ' + artist.lastName} />
            </> : <></>} */}
        </div>
    )
}

export default ArtistInfo
