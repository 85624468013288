import React from 'react'
import InnerBanner from '../components/InnerBanner'
import LogInForm from '../components/LogInForm'
import SubscribedToUpdate from '../components/SubscribedToUpdate'

const LogIn = () => {
  return (
    <>
     <InnerBanner title='Log In' />
     <LogInForm />
     <SubscribedToUpdate />
    </>
  )
}

export default LogIn