import React from 'react'

const MoodCard = ({data}) => {
    return (
        <>
            <a className="mood-item" href="/">
                <img src={data.image} alt={data.title} />
                <h5 className="theme-h5">{data.title}</h5>
            </a>
        </>
    )
}

export default MoodCard
