import React, { useContext, useEffect, useState } from 'react'
import './App.css'
import Header from './components/Header'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Home from './pages/Home'
import Music from './pages/Music'
import TopCharts from './pages/TopCharts'
import Footer from './components/Footer'
import Error404 from './pages/Error404'
import NewRelease from './pages/NewRelease'
import FeaturedArtists from './pages/FeaturedArtists'
import Playlist from './pages/Playlist'
import ArtistInfo from './pages/ArtistInfo'
import AlbumInfo from './pages/AlbumInfo'
import About from './pages/About'
import Contact from './pages/Contact'
import Register from './pages/Register'
import LogIn from './pages/LogIn'
import AdminHome from './pages/AdminHome'
import AdminArtist from './pages/AdminArtist'
import AdminAlbums from './pages/AdminAlbums'
import AdminMusic from './pages/AdminMusic'
import AdminPlaylist from './pages/AdminPlaylist'
import { UserDataContext } from './context/User'
import PlaylistInfo from './pages/PlaylistInfo'
import PlayerBottom from './components/PlayerBottom'
import { MusicContext } from './context/Music'

const ValidateAdminAccess = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')
  })
  return <></>
}

const App = () => {
  const { playingMusic } = useContext(MusicContext)
  const [userAdmin, setUserAdmin] = useState(false)
  const [showPlayer, setShowPlayer] = useState(false)
  const { userData } = useContext(UserDataContext)
  const location = useLocation()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [location]);
  useEffect(() => {
    setShowPlayer(false)
    setTimeout(() => {
      if(playingMusic){
        setShowPlayer(true)
      }
    }, 500);
  }, [playingMusic])
  
  useEffect(() => {
    if (userData?.role === 'admin') {
      setUserAdmin(true)
    } else {
      setUserAdmin(false)
    }
  }, [userData])
  return (
    <>
      {!location.pathname.includes('admin') && <Header />}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/music' element={<Music />} />
        <Route path='/top-charts' element={<TopCharts />} />
        <Route path='/new-release' element={<NewRelease />} />
        <Route path='/featured-artists' element={<FeaturedArtists />} />
        <Route path='/artist/:id' element={<ArtistInfo />} />
        <Route path='/album/:id' element={<AlbumInfo />} />
        <Route path='/playlists' element={<Playlist />} />
        <Route path='/playlist/:id' element={<PlaylistInfo />} />
        <Route path='/about-us' element={<About />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<LogIn />} />
        <Route path='/admin' element={userAdmin ? <AdminHome /> : <ValidateAdminAccess />} />
        <Route path='/admin/artist/*' element={userAdmin ? <AdminArtist /> : <ValidateAdminAccess />} />
        <Route path='/admin/album/*' element={userAdmin ? <AdminAlbums /> : <ValidateAdminAccess />} />
        <Route path='/admin/music/*' element={userAdmin ? <AdminMusic /> : <ValidateAdminAccess />} />
        <Route path='/admin/playlist/*' element={userAdmin ? <AdminPlaylist /> : <ValidateAdminAccess />} />
        <Route path='/*' element={<Error404 />} />
      </Routes>
      {/* {(!location.pathname.includes('admin') && showPlayer) && <PlayerBottom />} */}
      {!location.pathname.includes('admin') && <Footer />}
    </>
  )
}

export default App
