import React from 'react'
import Sidebar from '../components/admin/Sidebar'
import logo from '../images/logo.png'
import { Col, Row } from 'antd'

const AdminHome = () => {
    return (
        <>
            <div class="dashboard-wrapper">
            <Row gutter={{ xl: 30, lg: 20, xs: 15 }} style={{alignItems: 'center'}}>
                    <Col xl={3}>
                        <Sidebar />
                    </Col>
                    <Col xl={21}>
                        <div class="dashboard-content text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div class="w-100">
                                <Row style={{ justifyContent: 'center' }}>
                                    <Col xl={16} lg={20} xs={24}>
                                        <div class="text-center">
                                            <img class="img-fluid mb-4" style={{ maxWidth: '100%' }} src={logo} alt="" />
                                            <h1 class="theme-h1">Clarke's Music Services</h1>
                                            <p class="theme-p">Welcome to your CMS dashboard, your central hub for managing content effortlessly.
                                                Create, edit, and organize content with ease, ensuring a seamless online experience for your audience.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default AdminHome
