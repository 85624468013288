import React, { useEffect, useState } from 'react';
import { Table, Empty, Space, Popconfirm, message } from 'antd';
import { LuFileEdit } from "react-icons/lu";
import { MdDelete } from "react-icons/md";

const AdminTable = ({ data, columns, currentPage, fetchData, setOpenUpdateDrawer, setUpdateObj }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [formatedColumns, setFormatedColumns] = useState([])
    const [pagination, setPagination] = useState({
        current: currentPage,
        pageSize: 10
    });
    const confirm = async (key, actions) => {
        console.log(key);
        console.log('actions', actions);
        messageApi.open({
            type: 'loading',
            key,
            content: 'Action in progress..',
        })
        
        const res = await fetch(actions.delete, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ id: key })
        })
        const resJson = await res.json()
        if (resJson.success) {
            messageApi.open({
                type: 'success',
                key,
                content: 'Item Deleted Successfully',
            })
        } else {
            messageApi.open({
                type: 'error',
                key,
                content: resJson.error,
            })
        }
        fetchData()
    };
    const cancel = (key) => {
        console.log(key);
        // message.error('Click on No');
        messageApi.open({
            type: 'error',
            key,
            content: 'You Cancel deleting Item',
        })
    };
    useEffect(() => {
        let actionExists = false
        columns.forEach(col => {
            if (col.title === 'Action') {
                actionExists = true
            }
        })
        if (!actionExists) {
            let col = columns
            col.push({
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                    <Space size="middle">
                        <button
                        className='action-btn edit-btn'
                        onClick={()=>{
                            
                            setOpenUpdateDrawer(true)
                            setUpdateObj(record.actions.data)
                        }}
                        >
                            <LuFileEdit />
                        </button>
                        <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={() => confirm(record.key, record.actions)}
                            onCancel={() => cancel(record.key)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <button className='action-btn delete-btn'><MdDelete /></button>
                        </Popconfirm>
                    </Space>
                ),
            })
            setFormatedColumns(col)
        }
    }, [])
    return (
        <>
            {contextHolder}
            {data.length > 0 ? <Table pagination={pagination} bodySortBg='#111' columns={formatedColumns} dataSource={data} /> : <Empty className='empty-data' />}
        </>
    )
};
export default AdminTable;