import { Col, Row } from 'antd'
import React, { useState, useEffect } from 'react'
import PlaylistCard from './PlaylistCard'
// import { topHits } from '../playlistdata'

const TopHit = () => {
    const [playlists, setPlaylists] = useState([])
    const fetchPlaylists = async () => {
        const res = await fetch('/api/playlist/fetch', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const resJson = await res.json()
        console.log('resJson', resJson)
        if (resJson.success) {

            setPlaylists(resJson.playlists)
        }
    }
    useEffect(() => {
        fetchPlaylists()
    }, [])
    return (
        <>
            <div className="top-hits-wrapper">
                <div className="container">
                    <div className="content-wrapper">
                        <h3 className="theme-h3">Top Hits</h3>
                        <div className="tophits-listing">
                            <Row gutter={80}>
                                {playlists.map((data, index) => {
                                    return (
                                        <Col span={6}>
                                            <PlaylistCard data={data} />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopHit
