import React from 'react'
import Ball1 from '../images/item-1.png'
import ball2 from '../images/item-2.png'
import ball3 from '../images/item-3.png'
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';


export default function About() {
  return (
    <div>
      <section className='three_dots'>
        <div className="container">
          <div className="cost-banner">
          <div className="custom_dots-1">
            <div className="content">
                <h3>Listen the music with your own taste</h3>
                <img src={Ball1} className='img-fluid' alt="img" />
                {/* <Link to="/">Explore</Link> */}
                {/* <span><FaArrowRight /></span> */}
            </div>
            </div>
            <div className="custom_dots-2">
            <div className="content">
                <h3>Create your own playlist according</h3>
                <img src={ball2} className='img-fluid' alt="img" />
                {/* <Link to="/">Explore</Link> */}
                {/* <span><FaArrowRight /></span> */}
            </div>
            </div>
            <div className="custom_dots-3">
            <div className="content">
                <h3>Download and listen online no hassle !</h3>
                <img src={ball3} className='img-fluid' alt="img" />
                {/* <Link to="/">Explore</Link> */}
                {/* <span><FaArrowRight /></span> */}
            </div>

            </div>
          </div>

        </div>
      </section>
    </div>
  )
}

